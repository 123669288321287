/*
 * Dashforge Auth Pages (Signin, Signup, etc.)
 *
 * This style is use in auth pages.
 *
 */

// Variables
@import "../variables";
@import "../bootstrap/variables";
// Mixins
@import "../mixins";
@import "../bootstrap/mixins";
// Bootsrap
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
.content-auth {
    min-height: calc(100vh - #{$height-header + $height-footer});
    display: flex;
    flex-direction: column;
    justify-content: center;
    >.container {
        padding: 0;
        @media (max-width: 1139px) {
            max-width: none;
            padding: 0 20px;
        }
        &:first-child {
            flex: 1;
        }
    }
}

.content-auth-alt {
    // min-height: calc(100vh - #{$height-header + $height-footer});
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .container {
        padding: 0;
        @media (max-width: 1139px) {
            max-width: none;
        }
    }
}

.sign-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 340px;
    @include media-breakpoint-up(xl) {
        width: 340px;
    }
    .form-group {
        label {
            display: block;
            margin-bottom: 5px;
        }
    }
}